export function CallSummaryTagsCard() {
  return <div className="flex h-[198px] flex-col items-start justify-start self-stretch rounded-md border bg-white" data-sentry-component="CallSummaryTagsCard" data-sentry-source-file="call-summary-tags-card.tsx">
      <div className="inline-flex items-center justify-between self-stretch border-b p-4">
        <div className="text-sm font-semibold leading-[17.50px] text-tpl-navy">
          Tags
        </div>
      </div>
      <div className="flex h-[148px] flex-col items-start justify-start gap-4 self-stretch p-4">
        <div className="flex h-[54px] flex-col items-start justify-start gap-1 self-stretch">
          <div className="text-center text-sm font-semibold leading-[17.50px] text-tpl-navy">
            Intent
          </div>
          <div className="inline-flex h-8 items-center justify-between self-stretch rounded border bg-violet-50 px-2 py-1.5">
            <div className="shrink grow basis-0 text-sm leading-[17.50px] text-gray-400">
              Select
            </div>
            <div className="relative h-3 w-3" />
          </div>
        </div>
        <div className="flex flex-col items-start justify-center gap-1">
          <div className="text-center text-sm font-semibold leading-[17.50px] text-tpl-navy">
            Outcome
          </div>
          <div className="inline-flex items-center justify-center gap-4">
            <div className="bg-white/opacity-0 flex items-center justify-start gap-2">
              <div className="relative h-4 w-4">
                <div className="absolute left-0 top-0 h-4 w-4 rounded-full" />
                <div className="absolute left-0 top-0 h-4 w-4 rounded-[100px] border border-blue-400 bg-blue-400" />
                <div className="absolute left-[4px] top-[4px] h-2 w-2 rounded-[20px] bg-white" />
              </div>
              <div className="text-sm leading-normal text-tpl-navy">
                Resolved
              </div>
            </div>
            <div className="bg-white/opacity-0 flex items-center justify-start gap-2">
              <div className="h-4 w-4 rounded-[100px] border border-slate-100 bg-slate-100" />
              <div className="text-sm leading-normal text-tpl-navy">
                Unresolved
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>;
}