"use client";

/**
 * Project components.
 */
import { InboxEmptyWithShadowsIcon } from "@/components/client/icons";
import Icon from "@ant-design/icons";
import { HTMLAttributes, useMemo } from "react";
type PlaceholderProps = {
  /**
   * Message to display underneath the empty icon.
   */
  message: string;
  /**
   * Size of the placeholder.
   *
   * @default medium
   */
  size?: "small" | "medium" | "large";
} & Pick<HTMLAttributes<HTMLDivElement>, "className">;

/**
 * A section that is displayed when no communication log is selected.
 * This displays an icon and a text that says "Select a log".
 */
export function Placeholder({
  className = "",
  message,
  size = "medium"
}: PlaceholderProps) {
  // ===========================================================================
  // ===========================================================================
  // States
  // ===========================================================================
  // ===========================================================================

  const sizes = useMemo(() => {
    switch (size) {
      case "small":
        return {
          text: "text-sm",
          icon: "!text-5xl"
        };
      case "medium":
        return {
          text: "text-sm",
          icon: "!text-6xl"
        };
      case "large":
        return {
          text: "text-sm",
          icon: "!text-7xl"
        };
      default:
        throw new Error("Invalid size");
    }
  }, [size]);

  // ===========================================================================
  // ===========================================================================
  // Render
  // ===========================================================================
  // ===========================================================================

  return <div className={`flex h-full w-full flex-col items-center justify-center gap-2 ${className}`} data-sentry-component="Placeholder" data-sentry-source-file="placeholder.tsx">
      <div>
        <Icon component={InboxEmptyWithShadowsIcon} className={`${sizes.icon} !text-tpl-navy-light`} data-sentry-element="Icon" data-sentry-source-file="placeholder.tsx" />
      </div>
      <div className={`text-center ${sizes.text} text-tpl-navy-light`}>
        {message}
      </div>
    </div>;
}
export default PlaceholderProps;