import { CommunicationLogStatus } from "../communication-log";

/**
 * Types of activity log records.
 */
export enum ActivityLogType {
  CALL = "Call",
  MESSAGE = "Message",
  TASK = "Task",
}

/**
 * Properties of an activity log record that is a call.
 */
type ActivityLogCall = {
  /**
   * Status of the call.
   */
  status: CommunicationLogStatus;
  /**
   * Type of the activity log.
   */
  type: ActivityLogType.CALL;
};

/**
 * Properties of an activity log record that is a message.
 */
type ActivityLogMessages = {
  /**
   * Replies to the message.
   */
  replies?: ActivityLogBase[];
  /**
   * Type of the activity log.
   */
  type: ActivityLogType.MESSAGE;
};

/**
 * Properties of an activity log record that is a task.
 */
type ActivityLogTask = {
  /**
   * Status of the task.
   */
  status: "Completed" | "Pending";
  /**
   * Type of the activity log.
   */
  type: ActivityLogType.TASK;
};

/**
 * Base properties of an activity log record.
 */
type ActivityLogBase = {
  /**
   * Description of the activity log.
   * This displays below the title of each activity log entry.
   */
  description?: string;
  /**
   * Formatted time string.
   *
   * @example
   * 3:30PM
   */
  time: string;
  /**
   * Title of the activity log.
   * This displays on the header of each activity log entry.
   *
   * @example
   * Completed Call - Inbound
   */
  title: string;
  /**
   * Initials of the user who is associated with the activity.
   *
   * @example
   * MS
   */
  userInitials?: string;
};

/**
 * Properties of an activity log record.
 */
export type ActivityLog = ActivityLogBase &
  (ActivityLogCall | ActivityLogMessages | ActivityLogTask);
