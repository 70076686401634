/**
 * Third-party libraries.
 */
import { PlusOutlined, SmileOutlined } from "@ant-design/icons";
import { Button, Divider, Input, Tabs } from "antd";

/**
 * Project components.
 */
import { Card } from "@/components/client/card";
import { CommunicationLogInboundCallStatusMapper, CommunicationLogOutboundCallStatusMapper } from "@/components/client/communication-log";
import { CallDirection, usePastInteractionsQuery } from "@/components/client/graphql";
import { Placeholder } from "@/components/client/placeholder";
import useTabs from "@/components/client/tab/hooks/use-tabs";
import { RichTextEditor } from "@/components/client/text-editor";
import { TimeUtility } from "@/components/common/time";
import { GroupedByDate, ObjectUtility, StringUtility } from "@/components/common/utilities";
import { useMemo } from "react";
import { ActivityLogGroup } from "./activity-log-group";
import { ActivityLogTab } from "./enumerations";
import { ActivityLog, ActivityLogType } from "./types";

/**
 * Properties of the ActivityLogCard component.
 */
type ActivityLogCardProps = {
  /**
   * Phone number of the customer.
   *
   * This is used to query the past interactions of the customer.
   */
  customerPhoneNumber?: string;
  /**
   * Indicates if the card is visible.
   */
  visible?: boolean;
};

/**
 * A card that displays the activity logs.
 */
export function ActivityLogCard(props: ActivityLogCardProps) {
  // ===========================================================================
  // Hooks
  // ===========================================================================

  /**
   * Query that contains the past interactions with `clientPhoneNumber` based
   * on the `to` OR `from` record.
   *
   * This will contain the currently selected call as well because the `endDate` is inclusive of the current call.
   *
   * TODO: Add pagination.
   */
  const {
    data
  } = usePastInteractionsQuery({
    skip: !props?.customerPhoneNumber || !props.visible,
    variables: {
      clientPhoneNumber: props?.customerPhoneNumber
    }
  });

  /**
   * The logs grouped by date.
   */
  const logsGroupedByDate = useMemo<GroupedByDate<ActivityLog>>(() => {
    return ObjectUtility.groupByDate({
      data: data?.calls || [],
      key: "date",
      /**
       * Custom mapper that converts the calls to activity logs.
       */
      mapper: call => {
        function getTitle() {
          const status = StringUtility.toTitleCase({
            text: call.status
          });
          switch (call.direction) {
            case CallDirection.Inbound:
              return `${status} Call - Inbound`;
            case CallDirection.Outbound:
              return `${status} Call - Outbound`;
            default:
              throw new Error("Invalid Call Direction");
          }
        }
        function getStatus() {
          switch (call.direction) {
            case CallDirection.Inbound:
              return CommunicationLogInboundCallStatusMapper[call.status];
            case CallDirection.Outbound:
              return CommunicationLogOutboundCallStatusMapper[call.status];
            default:
              throw new Error("Invalid Call Direction");
          }
        }
        return {
          status: getStatus(),
          time: TimeUtility.formatTime(call.date),
          title: getTitle(),
          type: ActivityLogType.CALL
        };
      }
    });
  }, [data]);

  // ===========================================================================
  // States
  // ===========================================================================

  const {
    tabs,
    activeTabKey,
    setActiveTabKey
  } = useTabs({
    tabs: Object.values(ActivityLogTab).map(tab => ({
      key: tab,
      label: tab
    }))
  });
  return <Card header={<div className="flex h-full w-full items-center justify-between">
          <Card.Title title="Activity Log" />
          <Button icon={<PlusOutlined />} type="primary">
            Create Task
          </Button>
        </div>} bodyAttributes={{
    style: {
      // padding: 0,
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0
    }
  }} data-sentry-element="Card" data-sentry-component="ActivityLogCard" data-sentry-source-file="activity-log-card.tsx">
      <div className="w-full px-2">
        <Tabs activeKey={activeTabKey} className="w-full" items={tabs} onChange={key => {
        setActiveTabKey(key);
      }} data-sentry-element="Tabs" data-sentry-source-file="activity-log-card.tsx" />
      </div>
      <div className="flex max-h-72 min-h-[100px] w-full flex-col gap-2 overflow-y-auto px-4">
        {activeTabKey === ActivityLogTab.ALL && logsGroupedByDate.map(group => <ActivityLogGroup data={group} key={group.label} />)}
        {activeTabKey === ActivityLogTab.UNRESOLVED && <Placeholder message="No Logs" size="large" />}
      </div>
      <Divider className="mt-0 w-full" data-sentry-element="Divider" data-sentry-source-file="activity-log-card.tsx" />
      <div className="flex w-full gap-2 px-4">
        <Input.TextArea autoSize={{
        minRows: 1
      }} className="!bg-neutral-grey-light" style={{
        display: "none"
      }} data-sentry-element="unknown" data-sentry-source-file="activity-log-card.tsx" />
        <RichTextEditor
      // onCreate={({ editor }) => {
      //   editorRef.current = editor;
      // }}
      // onHotkey_MOD_S={updateCallSummary}
      // // onBlur={updateCallSummary}
      // onHotkey_ESC={() => {
      //   editorRef?.current?.commands.blur();
      //   cancelEditing();
      // }}
      placeholder="Enter text here" content={""}
      // editable={false}
      showBubbleMenu={false} showFloatingMenu={false}
      // onUpdate={({ editor }) => {
      //   const value = editor.getHTML();
      //   setEditorContent(value);
      // }}
      data-sentry-element="RichTextEditor" data-sentry-source-file="activity-log-card.tsx" />
        <Button type="text" icon={<SmileOutlined />} data-sentry-element="Button" data-sentry-source-file="activity-log-card.tsx" />
      </div>
    </Card>;
}