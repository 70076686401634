import { TabsProps } from "antd";
import { useState } from "react";

type UseTabsArgs = {
  /**
   * The tabs to display.
   */
  tabs: TabsProps["items"];
};

/**
 * A hook to work with AntD tabs.
 */
const useTabs = (args: UseTabsArgs) => {
  // ===========================================================================
  // States
  // ===========================================================================
  /**
   * Key of the active tab.
   */
  const [activeTabKey, setActiveTabKey] = useState<string | undefined>(
    args.tabs?.[0]?.key,
  );

  /**
   * The tabs.
   */
  const [tabs] = useState<UseTabsArgs["tabs"]>(args.tabs);

  // ===========================================================================
  // Render
  // ===========================================================================

  return {
    activeTabKey,
    setActiveTabKey,
    tabs,
  };
};

export default useTabs;
