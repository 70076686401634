/**
 * Third-party libraries.
 */
import { CheckCircleFilled, MessageOutlined } from "@ant-design/icons";

/**
 * Project components.
 */
import { IconCommunicationLogPhone } from "@/components/client/icons";
import { Fragment } from "react";
import { CircleOutline } from "../images";
import { ActivityLogItem } from "./activity-log-item";
import { ActivityLog, ActivityLogType } from "./types";

/**
 * Properties of the ActivityLogs component.
 */
type ActivityLogsProps = {
  /**
   * List of activity log records.
   */
  logs: ActivityLog[];
};

/**
 * Displays multiple activity log records.
 * - Icon is displayed on the left side of the activity log.
 *    - The icon is determined by the type of activity log.
 * - If the activity log is a message, it will display the replies.
 *    - The replies will be displayed in a nested list.
 * - If the activity log is a task, it will display the status of the task.
 *    - The status will be displayed as a circle icon.
 *        - Green circle with a check for completed.
 *        - Hollow circle for pending.
 */
export function ActivityLogs(props: ActivityLogsProps) {
  return <div className="flex min-h-[100px] w-full flex-col gap-2" data-sentry-component="ActivityLogs" data-sentry-source-file="activity-logs.tsx">
      {props.logs.map((log, index) => {
      const getIcon = () => {
        switch (log.type) {
          case ActivityLogType.CALL:
            return <IconCommunicationLogPhone size="small" status={log.status} />;
          case ActivityLogType.MESSAGE:
            return <MessageOutlined className="!text-semantic-blue" />;
          case ActivityLogType.TASK:
            if (log.status === "Pending") {
              return <div className="h-[14px] w-[14px] hover:cursor-pointer">
                    <CircleOutline className="!bg-tpl-navy-light" />
                  </div>;
            } else if (log.status === "Completed") {
              return <div className="h-[14px] w-[14px] hover:cursor-pointer">
                    <CheckCircleFilled className="!text-semantic-green" />
                  </div>;
            }
          default:
            throw new Error("Invalid Activity Log Type");
        }
      };
      return <Fragment key={index}>
            <ActivityLogItem actions={log.type === ActivityLogType.MESSAGE ? [{
          label: `Hide Replies (${log.replies?.length || 0})`,
          onClick: () => {}
        }, {
          label: "Reply",
          onClick: () => {}
        }] : []} description={log.description} icon={getIcon()} time={log.time} title={log.title} userInitials={log.userInitials} />
            {log.type === ActivityLogType.MESSAGE && !!log.replies?.length && <div className="flex w-full flex-col gap-2 pl-7">
                {log.replies.map((reply, index) => {
            return <ActivityLogItem actions={[{
              label: "Reply",
              onClick: () => {}
            }]} description={reply.description} icon={getIcon()} key={index} onMenuClick={() => {}} time={reply.time} title={reply.title} userInitials={reply.userInitials} />;
          })}
              </div>}
          </Fragment>;
    })}
    </div>;
}