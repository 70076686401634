/**
 * Third-party libraries.
 */
import { CaretDownOutlined, CaretRightOutlined } from "@ant-design/icons";
import { Collapse, Divider } from "antd";

/**
 * Project components.
 */
import { GroupedByDate } from "@/components/common/utilities";
import { useState } from "react";
import { ActivityLogs } from "./activity-logs";
import { ActivityLog } from "./types";

/**
 * Properties of the ActivityLogGroup component.
 */
type ActivityLogGroupProps = {
  /**
   * The data to display in the activity log group.
   */
  data: GroupedByDate<ActivityLog>[0];
};

/**
 * Displays a collapsible group of activity logs.
 * The header of the group displays the date of the activity logs.
 */
export function ActivityLogGroup({
  data
}: ActivityLogGroupProps) {
  const [expanded, setExpanded] = useState<boolean>(true);
  return <div className="activity-log-collapse static block w-full" key={data.label} data-sentry-component="ActivityLogGroup" data-sentry-source-file="activity-log-group.tsx">
      <Collapse ghost activeKey={expanded ? [data.label] : []} items={[{
      key: data.label,
      showArrow: false,
      label: <div className="sticky top-0 z-10 inline-flex h-[18px] w-full items-center justify-between gap-2 bg-white p-4" onClick={() => {
        setExpanded(!expanded);
      }}>
                <div className="inline-flex h-[0px] shrink grow basis-0 flex-col items-center justify-center gap-2.5">
                  <Divider />
                </div>
                <div className="flex items-center justify-center text-sm font-normal text-tpl-navy">
                  {data.label}
                </div>
                <div className="relative h-4 w-4">
                  {expanded ? <CaretDownOutlined /> : <CaretRightOutlined />}
                </div>
                <div className="inline-flex h-[0px] shrink grow basis-0 flex-col items-center justify-center gap-2.5">
                  <Divider />
                </div>
              </div>,
      children: <div className="static flex h-full w-full">
                <ActivityLogs logs={data.items}
        // TODO: Sample data will be removed in the final implementation.
        // logs={[
        //   {
        //     description: "Incorrectly dialled number.",
        //     title: "Cancelled Call - Inbound",
        //     time: "3:30PM",
        //     status: CommunicationLogStatus.CANCELED,
        //     type: ActivityLogType.CALL,
        //   },
        //   {
        //     description:
        //       "The quick brown fox jumps over the lazy dogs over and over and over again.",
        //     title: "Completed Call - Inbound",
        //     time: "3:30PM",
        //     status: CommunicationLogStatus.COMPLETED,
        //     type: ActivityLogType.CALL,
        //   },
        //   {
        //     description:
        //       "The quick brown fox jumps over the lazy dogs over and over and over again. The quick brown fox jumps over the lazy dogs over and over and over again. The quick brown fox jumps over the lazy dogs over and over and over again. The quick brown fox jumps over the lazy dogs over and over and over again.",
        //     title: "Check The Quick Brown Fox",
        //     time: "3:30PM",
        //     status: CommunicationLogStatus.MISSED,
        //     type: ActivityLogType.CALL,
        //   },
        //   {
        //     replies: [
        //       {
        //         description: "The quick brown fox was here.",
        //         title: "Jane Doe",
        //         time: "3:30PM",
        //         userInitials: "JD",
        //       },
        //       {
        //         description: "The fox was quick but not brown.",
        //         title: "John Doe",
        //         time: "3:30PM",
        //         userInitials: "JD",
        //       },
        //       {
        //         description: "The fox was brown but not quick.",
        //         title: "Jim Doe",
        //         time: "3:30PM",
        //         userInitials: "JD",
        //       },
        //       {
        //         description: "It was brown and quick but not a fox.",
        //         title: "Josh Doe",
        //         time: "3:30PM",
        //         userInitials: "JD",
        //       },
        //     ],
        //     description: "The quick brown fox jumps over the lazy dog.",
        //     title: "James Doe",
        //     time: "3:30PM",
        //     type: ActivityLogType.MESSAGE,
        //     userInitials: "JD",
        //   },
        //   {
        //     description:
        //       "You will be rewarded $1 for completing this task.",
        //     title: "Do 100 squats.",
        //     time: "3:30PM",
        //     status: "Completed",
        //     type: ActivityLogType.TASK,
        //     userInitials: "MS",
        //   },
        //   {
        //     description:
        //       "Cartwhen for 1 hour non-stop. Penalties will be imposed for stopping.",
        //     title: "Cartwheel for 1 hour non-stop.",
        //     time: "3:30PM",
        //     status: "Pending",
        //     type: ActivityLogType.TASK,
        //     userInitials: "CT",
        //   },
        // ]}
        />
              </div>
    }]} data-sentry-element="Collapse" data-sentry-source-file="activity-log-group.tsx" />
    </div>;
}