/**
 * Third-party libraries.
 */
import { Button, Dropdown } from "antd";
import { ReactNode } from "react";

/**
 * Project components.
 */
import { UserAvatar } from "@/components/client/avatar";
import { Ellipsis } from "@/components/client/images";

/**
 * Properties of the ActivityLogItem component.
 */
type ActivityLogItemProps = {
  /**
   * Actions that can be added to the activity log item.
   *
   * This will be added as links above the time.
   */
  actions?: {
    label: string;
    onClick: () => void | (() => Promise<void>);
  }[];
  /**
   * Description of the activity log item.
   *
   * @example
   * Called parent to FU on SAT4VL trial.
   */
  description?: ReactNode;
  /**
   * Icon component to be displayed on the left side of the activity log item.
   */
  icon: ReactNode;
  /**
   * Callback when the icon is clicked.
   */
  onIconClick?: () => void | (() => Promise<void>);
  /**
   * Callback when the menu button is clicked.
   */
  onMenuClick?: () => void | (() => Promise<void>);
  /**
   * Formatted time string.
   *
   * @example
   * 3:30PM
   */
  time: string;
  /**
   * Title of the activity log item.
   *
   * @example
   * Completed Call - Inbound
   */
  title: string;
  /**
   * Initials of the user who is associated with the activity.
   */
  userInitials?: string;
};
type ActivityLogItemActionProps = {
  label: string;
  onClick: () => void | (() => Promise<void>);
};
function ActivityLogItemAction(props: ActivityLogItemActionProps) {
  return <div className="rounded-sm text-xs font-normal leading-none text-tpl-blue hover:cursor-pointer" onClick={props.onClick} data-sentry-component="ActivityLogItemAction" data-sentry-source-file="activity-log-item.tsx">
      {props.label}
    </div>;
}

/**
 * Displays a single row of item in the activity log.
 */
export function ActivityLogItem(props: ActivityLogItemProps) {
  // ===========================================================================
  // ===========================================================================
  // Render
  // ===========================================================================
  // ===========================================================================

  return <div className="inline-flex flex-col items-start justify-start gap-1 rounded-md py-1" data-sentry-component="ActivityLogItem" data-sentry-source-file="activity-log-item.tsx">
      <div className="flex w-full items-center justify-between">
        <div className="flex items-center gap-2 self-stretch">
          <div className={`flex h-4 w-4 items-center justify-center ${!!props.onIconClick ? "hover:cursor-pointer" : ""}`} onClick={() => {
          props.onIconClick?.();
        }}>
            {props.icon}
          </div>
          <UserAvatar initials={props.userInitials} size="small" data-sentry-element="UserAvatar" data-sentry-source-file="activity-log-item.tsx" />
          <div className="text-sm font-semibold text-tpl-navy">
            {props.title}
          </div>
        </div>
        {props.onMenuClick && <Dropdown menu={{
        items: [{
          key: "edit",
          label: "Edit"
        }, {
          key: "delete",
          label: "Delete"
        }]
      }} trigger={["click"]}>
            <Button icon={<Ellipsis className="h-full w-full" />} onClick={() => {
          props.onMenuClick?.();
        }} shape="circle" size="small" type="text" />
          </Dropdown>}
      </div>
      <div className="inline-flex items-center justify-start gap-4 self-stretch">
        <div className="ml-2 h-full border-l"></div>
        <div className="flex flex-col gap-1 py-1">
          <div className="shrink grow basis-0 text-sm font-normal text-tpl-navy">
            {props.description}
          </div>
          {!!props.actions?.length && <div className="inline-flex h-4 items-start justify-start gap-2">
              {props.actions.map((action, index) => <ActivityLogItemAction key={action.label} label={action.label} onClick={action.onClick} />)}
            </div>}
          <div className="inline-flex items-center justify-start text-xs font-normal text-tpl-navy-light">
            {props.time}
          </div>
        </div>
      </div>
    </div>;
}