"use client";

/**
 * Third-party libraries.
 */
import { Editor } from "@tiptap/core";
import { Button } from "antd";
import { useMemo, useRef, useState } from "react";

/**
 * Project components.
 */
import { useAuthenticationContext } from "@/components/client/authentication";
import { CommunicationLogStatus } from "@/components/client/communication-log";
import { CommunicationLog } from "@/components/client/communication-log/types";
import { useCallUpdateSummaryMutation } from "@/components/client/graphql";
import { useClickOutside } from "@/components/client/hooks/use-click-outside";
import { useMessageContext } from "@/components/client/message";
import { RichTextEditor } from "@/components/client/rich-text/rich-text-editor";
import { Auth0Permission } from "@/components/common/auth0/enumerations";
import { PermissionUtility } from "@/components/common/auth0/utilities";

/**
 * Props for the CallSummaryCard component.
 */
type CallSummaryCardProps = {
  /**
   * The data of the call.
   */
  data: Pick<CommunicationLog, "id" | "summary" | "status" | "user"> | null;
  /**
   * Determines if the card is disabled.
   * @default false
   */
  disabled?: boolean;
  /**
   * Determines if the card is visible.
   * @default false
   */
  visible?: boolean;
  /**
   * Determines if the call summary can be edited.
   * @default false
   */
  initialMode?: "edit" | "view";
};

/**
 * A card with a text area to input a summary about a call.
 */
export function CallSummaryCard(props: CallSummaryCardProps) {
  const {
    data: call,
    disabled = false,
    visible = false,
    initialMode = "view"
  } = props;
  const {
    user
  } = useAuthenticationContext();
  const message = useMessageContext();
  // ===========================================================================
  // ===========================================================================
  // States
  // ===========================================================================
  // ===========================================================================

  /**
   * The content of the call summary editor. This won't be committed until the
   * save button is clicked.
   *
   * This serves as a temporary storage for the editor content. This is reverted
   * when the "cancel" button is clicked.
   */
  const [editorContent, setEditorContent] = useState<string | undefined>(call?.summary);

  /** Controls if the call summary is currently being edited or not. */
  const [isEditing, setIsEditing] = useState(initialMode === "edit");

  /**
   * Previous states - used for tracking props changes.
   * To see more details, see the `useEffect` below.
   */
  const [prevCallSummary, setPrevCallSummary] = useState<string | undefined>();
  const [prevInitialMode, setPrevInitialMode] = useState<"view" | "edit">();
  const [prevCallStatus, setPrevCallStatus] = useState<CommunicationLogStatus>();

  /** Controls if the "Saved" text should be shown */
  const [showSavedText, setShowSavedText] = useState(false);

  /** Manages the timer for showing the "saved" text */
  const [timer, setTimer] = useState<NodeJS.Timeout>();

  /** The tiptap editor. */
  const editorRef = useRef<Editor | null>(null);

  /**
   * The user can only update the call summary if:
   * - The user has the `CALL_SUMMARY_UPDATE_ALL` permission.
   * - The user has the `CALL_SUMMARY_UPDATE_OWN` permission and the call is assigned to the user.
   */
  const userCanEdit = useMemo<boolean>(() => {
    return PermissionUtility.isAuthorized({
      userPermissions: user?.permissions || [],
      requiredPermissions: [Auth0Permission.CALL_SUMMARY_UPDATE_ALL]
    }) || PermissionUtility.isAuthorized({
      userPermissions: user?.permissions || [],
      requiredPermissions: [Auth0Permission.CALL_SUMMARY_UPDATE_OWN]
    }) && !!call?.user?.id && call?.user?.id === user?.id;
  }, [user, call]);

  /** The ref for the editor container. When clicked outside, will save changes then cancel editing. */
  const editorContainerRef = useClickOutside({
    handler: async () => {
      if (editorContent === call?.summary) {
        cancelEditing();
      }
      await updateCallSummary();
    },
    enabled: visible && isEditing
  });

  // ===========================================================================
  // ===========================================================================
  // Operations
  // ===========================================================================
  // ===========================================================================

  const [__updateCallSummary, {
    loading: updatingCallSummary
  }] = useCallUpdateSummaryMutation();

  // ===========================================================================
  // ===========================================================================
  // Functions
  // ===========================================================================
  // ===========================================================================

  /** Cancels editing the call summary. */
  const cancelEditing = () => {
    setEditorContent(call?.summary);
    editorRef?.current?.commands?.setContent(call?.summary || "");
    setIsEditing(false);
  };

  /**
   * Updates the call summary and sets the state.
   * Skips updates if any of the following conditions are met:
   * - When the call is `undefined | null`
   * - When editor is not in edit mode
   * - When the user cannot edit the call summary
   * - When there's no changes made
   * - When the call summary is being updated
   */
  const updateCallSummary = async () => {
    if (!call || !isEditing || updatingCallSummary) return;
    if (!userCanEdit) {
      message.error("User is not allowed to edit call summary.");
      return;
    }
    if (editorContent === call?.summary) {
      cancelEditing();
      return;
    }

    /** Clears the previously set timer when the call summary is saved again before the 3sec timer finishes. */
    clearTimeout(timer);
    await __updateCallSummary({
      variables: {
        input: {
          callId: call.id,
          summary: editorContent || ""
        }
      }
    });
    setIsEditing(false);
    /** Displays the "Saved" text */
    setShowSavedText(true);

    /** Sets the timer to 3secs to hide the "Saved" text */
    setTimer(setTimeout(() => setShowSavedText(false), 3000));
  };

  /** Starts editing the call summary. */
  const startEditing = () => {
    if (!userCanEdit) return;

    /** This is to prevent the editor from automatically moving to the end of the text when selecting texts. */
    if (!isEditing) {
      // Focus on the editor at the end of the text.
      editorRef.current?.commands.focus("end");
      setIsEditing(true);
    }
  };

  // ===========================================================================
  // ===========================================================================
  // Effects
  // ===========================================================================
  // ===========================================================================

  /**
   * Update the editor content, when the call.summary prop changes.
   * (i.e. selected a different communication log).
   *
   * Note: If this was put in useEffect, this causes the editor content to be
   * different from the call summary and the save button will become enabled even if there was no change.
   *
   * To avoid this, we instead use this pattern that is based on
   * https://react.dev/learn/you-might-not-need-an-effect#adjusting-some-state-when-a-prop-changes
   */
  if (call?.summary !== prevCallSummary) {
    if (call?.summary !== editorContent) {
      setEditorContent(call?.summary);
    }
    setPrevCallSummary(call?.summary);
  }

  /**
   * Starts the call summary as "edit" mode when the initial mode prop is changed to "edit".
   *
   * Note: If this was put in useEffect, saving would always set the editor as "editable",
   * because the initial mode is already set as "edit", and saving would cause a re-render.
   *
   * To avoid this, we instead use this pattern that is based on
   * https://react.dev/learn/you-might-not-need-an-effect#adjusting-some-state-when-a-prop-changes
   */
  if (prevInitialMode !== initialMode) {
    if (initialMode === "edit") {
      startEditing();
    }

    /**
     * TODO: [user-permission-refresh]
     * Replace this with the following code when the real-time update of the
     * user's permission is implemented in the future.
     *
     * This is because if the user cannot edit and the user's permission is updated,
     * we want this to be executed again so that the editor will be editable.
     * ```
     * if (userCanEdit) {
     *   setPrevInitialMode(initialMode);
     * }
     * ```
     */
    /**
     * Sets the previous initial mode so that we don't execute this block again when
     * the component re-renders except when the initial mode is changed to "edit".
     */
    setPrevInitialMode(initialMode);
  }

  /**
   * Auto-saves call summary when the call.status prop changes to "WRAPPING_UP"
   *
   * Note: If this was put in useEffect, when the call is in "wrapping up" state,
   * and the editorContent changes, it would immediately perform a save.
   *
   * To avoid this, we instead use this pattern that is based on
   * https://react.dev/learn/you-might-not-need-an-effect#adjusting-some-state-when-a-prop-changes
   */
  if (!!call && prevCallStatus !== call.status) {
    if (call.summary !== editorContent && call.status === CommunicationLogStatus.WRAPPING_UP) {
      updateCallSummary();
    }
    setPrevCallStatus(call.status);
  }

  // ===========================================================================
  // ===========================================================================
  // Render
  // ===========================================================================
  // ===========================================================================

  if (!visible) {
    return null;
  }
  return <div className="flex animate-slide-left flex-col items-start justify-start self-stretch rounded-md border bg-white" data-sentry-component="CallSummaryCard" data-sentry-source-file="call-summary-card.tsx">
      <div className="inline-flex items-center justify-between self-stretch border-b p-4" style={{
      borderBottom: "1px solid rgba(0,0,0,0.1)"
    }}>
        <div className="text-sm font-semibold leading-[17.50px] text-tpl-navy">
          Call Summary
        </div>

        {showSavedText && <div className="text-xs italic text-tpl-navy-light">Saved</div>}
      </div>

      <div className="flex flex-col items-start justify-start gap-4 self-stretch p-4" ref={editorContainerRef}>
        <div className={`w-full border transition-all ${userCanEdit ? "hover:bg-neutral-grey-light" : ""} ${isEditing ? "rounded-md border border-semantic-blue bg-neutral-grey-light" : "border-transparent"}`} onClick={() => startEditing()}>
          <RichTextEditor onCreate={({
          editor
        }) => {
          editorRef.current = editor;
        }} onHotkey_MOD_S={updateCallSummary} onHotkey_ESC={() => {
          editorRef?.current?.commands.blur();
          cancelEditing();
        }} placeholder="No call summary" content={call?.summary} editable={isEditing} showBubbleMenu={false} showFloatingMenu={false} onUpdate={({
          editor
        }) => {
          const value = editor.getHTML();
          setEditorContent(value);
        }} data-sentry-element="RichTextEditor" data-sentry-source-file="call-summary-card.tsx" />
        </div>
        {isEditing && <div className="flex w-full items-center justify-end gap-2">
            <Button type="primary" onClick={updateCallSummary} loading={updatingCallSummary} disabled={call?.summary === editorContent}>
              {updatingCallSummary ? "Saving..." : "Save"}
            </Button>
            <Button onClick={cancelEditing}>Cancel</Button>
          </div>}
      </div>
    </div>;
}